import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Page from "../Page";
import RegNavBar from "../RegNavBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../Footer";

const SchoolsRegistration = ({scope}) => {
    const navigate = useNavigate();
    useEffect(() => {
        const siteConfig = JSON.parse(localStorage.getItem('siteConfig') ?? '{}');
        siteConfig.isScopedToSchools = true;
        // localStorage.setItem('siteConfig', JSON.stringify(siteConfig));
        // navigate("/register");
    }, []);
    return (
        <div>

            <RegNavBar/>

            <Container>
                <Row >
                    <Col sm={12} md={1} className="d-none d-md-block">   </Col>

                    <Col sm={12} md={10}>

                        <Page title='Registration Closed'/>
                        <div className="card mb-5 text-dark bg-warning ">
                            <div className="card-body">
                                <h5 className="card-title text-capitalize pt-2 pb-3">Thank you for your interest</h5>
                                <p className="card-text pb-5">
                                    Registration for the National Secondary Schools Online Competition is now closed.
                                </p>
                            </div>
                        </div>

                    </Col>

                    <Col sm={12} md={1} className="d-none d-md-block"> </Col>
                </Row>
            </Container>
            <Footer />


        </div>
    );
}

export default SchoolsRegistration;