import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';
import {  useEffect } from "react";
import { BsBook } from "react-icons/bs";
import USER_MANUAL from "../pdfFiles/USER_MANUAL.pdf";
import PRIVACY_POLICY from '../pdfFiles/PRIVACY_POLICY.pdf';
import YouTubeVideo from '../Components/YoutubeVideo';
import Page from "../Components/Page";


const Resources = ()=>{

    return (<>
        <Page title='Resources'/>
        <Row className='home-header'>  
        
        <div className="p-1 mb-2 text-center" >
        <span className="inv-color fs-1 roboto-font"> Inve$tor </span> 
        <span className="quest-color fs-1 roboto-font"> Quest TT 2.0 <BsBook  size={30} style={{ fill: 'white' }} /> 
        </span>
        
        <p className="text-light fs-3 roboto-font"> 
        Unlock The Investor In You! </p>
            
        </div>   

    </Row>

    <Row className='border border-2 border-dark mb-5'>
    <Col xs={1}></Col>   

    <Col xs={10} >
    <h3 className='home-headings res-heading text-center mt-4 '>Resources </h3>

    <Row className='mt-2 mb-5'>
    <p className='my-3 roboto-font fs-3 ttsec-blue'>Playing Investor Quest: </p>

    <YouTubeVideo videoURL={'https://www.youtube.com/watch?v=4sU8qQs8od4'} />
    
    </Row>
    
    
    <Row className='my-3'>
    <p className='my-3 roboto-font fs-3 ttsec-blue'>Additional Resources: </p>
        <Col xs={12} md={6}>
          <Row>
            <Col>
            <div className='text-center px-1'>
            <a href={USER_MANUAL} target="_blank" rel="noopener noreferrer"> 
            <Image  src={require('../assets/images/userguide.png')}  width="150px" height="auto" className="text-right"/>
            </a>
            <p className='fs-5 currency'>Investor Quest 2.0</p>
            </div>
            </Col>

            <Col>
            <div className='text-center px-1'>
            <a href='https://investucatett.com/' target="_blank" rel="noopener noreferrer">
            <Image  src={require('../assets/images/SEC-Logo.png')}  width="150px" height="auto" className="text-right"/>
            </a>
            
            </div> 
            </Col>

                        
          </Row>

        </Col>

        <Col xs={12} md={6}>
          <Row>
          <Col>
          <div className='text-center px-1'>
            <a href='https://investucatett.com/glossary-3/' target="_blank" rel="noopener noreferrer"> 
            <Image  src={require('../assets/images/Glossary.jpg')}  width="150px" height="auto" className="text-right"/>
            </a>
            </div>
            </Col>

            <Col>
            <div className='text-center px-1'>
            <a href='https://www.stockex.co.tt/' target="_blank" rel="noopener noreferrer"> 
            <Image  src={require('../assets/images/TTSE-Logo.png')}  width="150px" height="auto" className="text-right"/>
            </a>
            </div>
            </Col>
          </Row>

        </Col>
     
        <p className='fs-5 currency justify-text border border-1 my-3 p-4'>
    <span className='fw-bold'> Privacy Policy:  </span> 
    <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer"> Click here </a> to 
    view our Privacy Policy. 
    </p>

    </Row>
   


    </Col>    

    <Col xs={1}></Col>    
    
</Row>
      
   
    </>);
}

export default Resources;