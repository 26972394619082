import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { useState, useEffect,useContext } from "react";
import AuthContext from '../Components/AuthContext';
import LoadingSpinner from '../Components/LoadingSpinner';
import { DateTime } from "luxon";
import Modal from 'react-bootstrap/Modal';
import startJourney from '../assets/audio/start-your-journey.mp3';
import { BsCoin } from "react-icons/bs";
import {
    canJoinQuestBasedOnAgeGroup,
    getApplicableAgeGroupsDescriptionForPlayer,
    isSecondarySchoolQuest
} from "../utils/quest";
import Page from "../Components/Page";
import moment from "moment";
import competitionRules from "../pdfFiles/TTSEC-National_Investing_Compeition-Guideline_and_Promotion_Details.pdf";
import {Alert} from "react-bootstrap";

const ViewQuest = ()=>{
    const navigate = useNavigate();
    const [joinStatus, setJoinStatus] = useState("");
    const [goToQuest, setGoToQuest] = useState(false);
    const [loadPage, setLoadPage] = useState(false);
    const [quest, setQuest] = useState({});
    const [ageGroup, setAgeGroup] = useState(""); 
    const audio = new Audio(startJourney); 
    var CurrencyFormat = require('react-currency-format');

    const [showGuidelines, setShowGuidelines] = useState(false);

    const {authToken}= useContext(AuthContext);

    const{questId} = useParams();

    const [details, setDetails] = useOutletContext();

    const [show, setShow] = useState(false);  
    

    const handleClose = () => setShow(false);      
  
    const handleShow = () => {
        setShow(true);
        audio.play();
    }

    useEffect(() => { 
        checkIfPlayerJoined();       
    },[]);

    useEffect(() => { 
        if(goToQuest){
            navigate(`/game/quest/${questId}`);
        }
    },[goToQuest]);

    useEffect(() => { 
        if(loadPage){
            setAgeGroup(getApplicableAgeGroupsDescriptionForPlayer(quest.applicable_age_groups));
        }
    },[loadPage]);

    const getQuestDetails = async () =>{
        const response = await fetch('/gameapi/getQuestById/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                questId: `${questId}`,
             })
        });
        const data = await response.json();
        data.canJoin = canJoinQuestBasedOnAgeGroup(data.applicable_age_groups, details.age_group)

        if(isSecondarySchoolQuest(data.applicable_age_groups)){
            setShowGuidelines(true);
        }
        if(data.canJoin && data.registration_end_date) {
            const now = moment();
            const regEndDate = moment(data.registration_end_date)
                .set({
                    hour: now.get('hour'),
                    minute: now.get('minute'),
                    seconds: now.get('seconds'),
                    milliseconds: now.get('milliseconds')
                });
            data.registrationClosed = regEndDate.diff(now, 'seconds') <= 0
            data.canJoin = !data.registrationClosed;
        }
        setQuest(data);
        setLoadPage(true);
    }

    const checkIfPlayerJoined = async () =>{
        const response = await fetch('/gameapi/didPlayerJoin/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + String(authToken.access)
            },
            body: JSON.stringify({
                qId: `${questId}`,
             })
        });
        const data = await response.json();
        setJoinStatus(data.message);
        if(data.message === 'ALREADY_JOINED')
            setGoToQuest(true);
        else
            getQuestDetails();
    }

    const joinQuest = async () =>{
        const response = await fetch('/gameapi/joinQuest/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + String(authToken.access)
            },
            body: JSON.stringify({
                qid: `${questId}`,
             })
        });
        const data = await response.json();
        setJoinStatus(data.message);
        if(data.message === 'JOINED_QUEST'){
            handleShow();
        }
        
        else if(data.message === 'ALREADY_JOINED_QUEST')
            alert ("joined already.")
        else
        alert ("Sorry, something went wrong.")
    }

    return(<>
        { (!loadPage) ?
        <> 
            <div className="p-3 mb-3 text-center"> 
                <LoadingSpinner/> 
            </div>        
        </>
        :
        <>
        <Page title={`${quest.name} - Quest`}/>
        <Container>

        
        <Row className='trading-form-bg pt-3 '>         
          
            <Col sm={12} md={7}>
            <h1 className="mb-3 px-2 quest-play-heading text-info "> {quest.name} </h1> 
            </Col>
            <Col sm={12} md={5}>
            
        
            <h5 className="mb-3 quest-play-startdate text-light fs-5"> Start Date: 
            {DateTime.fromISO(quest.start_date).toLocaleString(DateTime.DATE_MED)}
             </h5> 
            <h5 className="mb-3 quest-play-startdate text-light fs-5"> End Date: 
            {DateTime.fromISO(quest.end_date).toLocaleString(DateTime.DATE_MED)}
             </h5> 
     
          
            </Col> 
                      
        </Row>  
           
    
   <Row className='border border-2 border-dark mb-5'>
    <Row>
    <Col md={0} lg={1}></Col>

    <Col md={0} lg={10}>
    <p className='my-4 fs-5 justify-text'> {quest.description}</p>

    <Row>
        <Col className='fs-5' >
        <p className='quest-join-label fs-3'> Starting Value: <span className='currency fs-3 text-success'> 
            <CurrencyFormat value={parseFloat(quest.start_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
            /> 
            </span>
            </p>              
        </Col>        
    </Row>  

    <Row className='my-3'> 

<Col md={12} lg={6}>
    <h3 className='quest-join-label fs-3'> Stocks to be Traded: </h3>
    <Row>
        <Col sm={0} md={1}></Col>
        <Col sm={12} md={11}>
    
        { quest.stocks_and_mf.map((s, index)=>(
            <div key={index} className='mb-1'>
                {(s.type === 'STOCK') ?
                <>
                <BsCoin size={25} style={{ fill: '#ffc107' }} /> 
                <span className='fs-5'> {s.registered_entity} </span>                
                </>
                :<></>
            }
            </div>           
            
        ))}
    
        </Col>
    </Row>
</Col>

<Col md={12} lg={6}>

<h3 className='quest-join-label fs-3'> Mutual Funds to be Traded: </h3>
    <Row>
        <Col sm={0} md={1}></Col>
        <Col sm={12} md={11}>
    
        { quest.stocks_and_mf.map((m, index)=>(
            <div key={index} className='mb-1'>
                {(m.type === 'MUTUAL_FUND') ?
                <>
                <BsCoin size={25} style={{ fill: '#ffc107' }} /> 
                <span className='fs-5'> {m.registered_entity} </span>                
                </>
                :<></>
            }
            </div>           
            
        ))}            
        </Col>
    </Row>        
</Col>
        
</Row>

<Row className='my-5'> 

            <Col sm={12} md={6}>
                <h3 className='quest-join-label fs-3'> Bonds to be Traded: </h3>
                <Row>
                    <Col sm={0} md={1}></Col>
                    <Col sm={12} md={11}>
                
                    { quest.bonds.map((b, index)=>(
                        <div key={index} className='mb-1'>
                            <BsCoin size={25} style={{ fill: '#ffc107' }} /> 
                            <span className='fs-5'> {b.registered_entity} </span>                
                        </div>           
                        
                    ))}
                
                    </Col>
                </Row>
            </Col>
        </Row>
        
        
    </Col>

    <Col md={0} lg={1}></Col>
   
    </Row>


    <Row className='mt-3 mb-5'>
    <Col>
    {(quest.canJoin) ?
        <div>
            {showGuidelines &&
                <Alert variant="warning">
                    <h4>Competition Rules</h4>
                    Please ensure that you <a download target="_blank" href={competitionRules}>review the competition
                    rules</a> before continuing.
                </Alert>
            }
            <div className='text-end'>
            <Button variant="primary"  className='mx-2 ' onClick={joinQuest}>
                Join Quest
            </Button>
            </div>
        </div>
    :
    <div className='text-center'>
        {
            quest.registrationClosed
                ? <p className='text-primary fs-5 '>
                    You are not able to join this quest. Registration is now closed.
                </p>
                : <p className='text-primary fs-5 '>You are not able to join this quest.
                    It is only available to the age group(s): {ageGroup}.</p>
        }

    <Button variant="warning"  className='mx-2' onClick={() => navigate("/game/")}>
        Back to Home
    </Button>
    </div>
    }
    </Col>
    </Row>  
    </Row>
   
</Container>

<Modal show={show} onHide={handleClose} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Joining a Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> You have joined {quest.name}</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={() => navigate(`/game/quest/${questId}`)}> Continue </Button> 
        </Modal.Footer>
    </Modal>
        
        </>
        }
        
    
    </>);
}

export default ViewQuest;