export function isScopedToSchools() {
    const siteConfig = JSON.parse(localStorage.getItem('siteConfig')?? '{}');
    return siteConfig?.isScopedToSchools === true;
}

export function scopeToSchools(){
    const siteConfig = JSON.parse(localStorage.getItem('siteConfig')?? '{}');
    siteConfig.isScopedToSchools = true;
    localStorage.setItem('siteConfig', JSON.stringify(siteConfig));
}

export function removeScopeToSchools(){
    const siteConfig = JSON.parse(localStorage.getItem('siteConfig')?? '{}');
    siteConfig.isScopedToSchools = false;
    localStorage.setItem('siteConfig', JSON.stringify(siteConfig));
}

